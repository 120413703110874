






























































import Vue from 'vue';
import { ListUser, GET_CURRENT_USER, GET_FILTERED_USERS } from '@/store/users';
import { mapGetters, mapActions } from 'vuex';
import { GET_ACDB } from '@/store/acdb';
import { Role, FETCH_USER_ROLES } from '@/store/roles';
import { find } from 'lodash-es';

export default Vue.extend({
  name: "UserViewExpandedCard",
  props: {
    userId: {
      type: Number,
      required: true,
    },
    selectUserToEdit: {
      type: Function,
      required: true,
    },
  },
  async created() {
    await this.fetchUserRoles(this.user.id);
  },
  data() {
    return {
      roles: [] as Role[] | undefined
    };
  },
  methods: {
    ...mapActions({
      fetchUserRoles: FETCH_USER_ROLES
    }),
    canReadUserAssignment() {
      return this.getAcdb('read', 'roleAssignment').hasAccess;
    },
    canEditUser(user: ListUser) {
      // Has the user the permission to update any user ?           // Or its own user ?
      return (
        this.getAcdb('update', 'user').hasAccess === true ||
        (this.getAcdb('update', 'ownUser').hasAccess === true &&
          this.currentUser.id === user.id)
      );
    },
    random() {
      return Math.floor(Math.random() * 100);
    }
  },
  computed: {
    ...mapGetters({
      getAcdb: GET_ACDB,
      currentUser: GET_CURRENT_USER,
      filteredUsers: GET_FILTERED_USERS
    }),
    user: function(): ListUser {
      return find(this.filteredUsers, user => user.id === this.userId);
    }
  },
  watch: {
    user: {
      deep: true,
      immediate: true,
      handler: async function() {
        await this.fetchUserRoles(this.user.id);
        this.roles = this.user.roles;
      }
    },
    filteredUsers: {
      deep: true,
      immediate: true,
      handler: function() {
        this.roles = find(this.filteredUsers, user => user.id === this.userId).roles || [];
      }
    }
  }
});
